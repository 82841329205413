<template>
  <div>
    <b-button
      size="sm"
      @click="flagCommentForm = true"
      class="mr-1"
      style="width:100%;"
    >
      Califica
    </b-button>
    <div class="m-4">
      <CToaster :autohide="3000">
        <template v-for="toast in fixedToasts">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Calificación enviada"
          >
            Gracias por tu calificación
          </CToast>
        </template>
      </CToaster>
    </div>
    <b-modal
      title="Califica y comenta el servicio del paseador"
      v-model="flagCommentForm"
      @ok="submit"
    >
      <loading
        :active.sync="loading"
        :can-cancel="false"
        background-color="#fff"
        :is-full-page="false"
      >
      </loading>
      <form @submit.prevent>
        <div class="animated fadeIn">
          <b-row>
            <b-col sm="12">
              <b-row v-if="error">
                <b-col cols="12">
                  <div
                    class="alert alert-danger"
                    role="alert"
                    v-for="error_message in errors"
                  >
                    {{ error_message }}
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group>
                    <label for="score-creation" class="score-selector-label"
                      >Calificación: {{ score }}</label
                    >
                    <b-form-rating v-model.trim="$v.score.$model">
                    </b-form-rating>
                    <b-form-invalid-feedback v-if="!$v.score.required">
                      Una calificación es necesaria
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group>
                    <label for="name">Comentarios</label>
                    <b-form-input
                      type="text"
                      id="comment"
                      v-model.trim="$v.comment.$model"
                      :class="{
                        'form-control is-invalid': $v.comment.$error,
                        'form-control is-valid': !$v.comment.$invalid
                      }"
                    >
                    </b-form-input>
                    <!-- <b-form-invalid-feedback v-if="!$v.comment.required">
                        El comentario 
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.comment.minLength">
                        La recomendación debe tener como mínimo {{$v.comment.$params.minLength.min}} letras
                      </b-form-invalid-feedback> -->
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </form>

      <template #modal-footer>
        <div class="w-100">
          <b-row>
            <b-col sm="6" md="3" offset-md="6">
              <b-button
                size="sm"
                class="float-right w-100"
                @click="flagCommentForm = false"
                style="margin-bottom:1.5rem"
              >
                Cancelar
              </b-button>
            </b-col>

            <b-col sm="6" md="3">
              <b-button
                size="sm"
                class="float-right  s-bg s-bg-text mr-1 w-100"
                @click="submit"
              >
                Calificar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required, minLength, email, numeric } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "qualifyWalker",
  props: ["serviceInfo"],
  components: {
    Loading
  },
  data() {
    return {
      flagCommentForm: false,
      score: 1,
      comment: null,
      error: false,
      errors: [],
      loading: false,
      value: null,
      fixedToasts: 0,
      serviceId: null
    };
  },
  validations: {
    comment: {},
    score: {
      required
    }
  },

  created() {
    //comes from email notification
    if (this.serviceInfo == undefined) {
      this.serviceId = this.$route.query.id;

      let userProfile = JSON.parse(this.$store.getters.userProfile);
      if (userProfile == null) {
        this.$router.push({
          name: "Login",
          query: {
            redirect: "serviceQualification",
            id: this.serviceId
          }
        });
      }
      this.fetchService();
    }
  },
  computed: {
    service: {
      get() {
        if (this.serviceInfo != undefined) {
          return this.serviceInfo;
        }
      },
      set(newValue) {
        return newValue;
      }
    }
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = true;
        this.errors = [
          "No has completado correctamente la información para calificar"
        ];
      } else {
        // console.log(this.service)
        let userProfile = JSON.parse(this.$store.getters.userProfile);
        let clientId = userProfile.id;
        let clientName = userProfile.nombre;
        let walkerId = this.serviceInfo.walkerId;
        let walkerName = this.serviceInfo.walkerName;

        let serviceId = this.serviceInfo.id;
        let petId = this.serviceInfo.petId;
        let petName = this.serviceInfo.petName;

        let newRecord = {
          clientId,
          clientName,
          walkerId,
          walkerName,

          serviceId,
          petName,

          score: this.score,
          comment: this.comment
        };
        // console.log(newRecord)
        this.loading = true;
        this.postQualification(newRecord);
      }
    },
    postQualification(payload) {
      let createQualification = this.$firebase
        .functions()
        .httpsCallable("createServiceQualification");
      createQualification(payload)
        .then(response => {
          // console.log(response.data.data);
          this.fixedToasts++;
          this.score = 1;
          this.comment = null;
          this.flagCommentForm = false;
          this.loading = false
        })
        .catch(error => {
          console.log(error);
          this.loading = false
        });
    },
    fetchService() {
      let getService = this.$firebase
        .functions()
        .httpsCallable("getServiceById");
      let data = { serviceId: this.serviceId };
      getService(data)
        .then(response => {
          let data = response.data.data;
          // console.log(data)
          data["petName"] = data.mascota.nombre;
          data["walkerId"] = data.usuario.id;
          data["walkerName"] = data.usuario.nombres;
          data["petId"] = data.mascota.id;

          this.serviceInfo = data;
          this.flagCommentForm = true;
        })
        .catch(error => {
          console.log("error: ", error);
        });
    }
  }
};
</script>

<style scoped>
.v-stars-comment {
  font-size: 1.5rem;
}

.score-selector-label {
  display: block;
}
</style>
